import styled from '@emotion/styled';
import type { FC } from 'react';
import { Breakpoint, Color } from '@pafcloud/style';
import { Icon } from '../icon';

type Direction = 'left' | 'right';

const DirectionNav = styled.button<{ direction: Direction }>(
  {
    position: 'absolute',
    top: 0,
    zIndex: 2,

    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',

    width: 'var(--padding-inline)',
    height: '100%',

    padding: 16,
    border: 0,
    background: 'none',
    fontSize: '2rem',

    cursor: 'pointer',
    color: `var(--nav-button-icon, ${Color.HighlightedText})`,
    filter: `drop-shadow(0 0 4px var(--nav-button-icon-glow, ${Color.Background}))`,

    [Breakpoint.LaptopOrLarger]: {
      display: 'flex',
      opacity: 0,
      transition: 'opacity 0.2s',

      'div:hover > &': {
        opacity: 1,
      },
    },
  },
  ({ direction }) => {
    if (direction === 'right') {
      return {
        right: 0,
        justifyContent: 'flex-end',
      };
    }

    if (direction === 'left') {
      return {
        left: 0,
        justifyContent: 'flex-start',

        svg: {
          transform: 'scaleX(-1)',
        },
      };
    }
  },
);
type Props = {
  direction: Direction;
  onClick: () => void;
};

export const NavButton: FC<Props> = ({ direction, onClick }) => {
  return (
    <DirectionNav direction={direction} onClick={onClick}>
      <Icon name="chevronRight" size="1em" />
    </DirectionNav>
  );
};
